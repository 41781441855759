import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { parse } from "query-string"

import { Subscribe } from "unstated"
import RPContainer from "../../store"
import ProductStore from "../../stores/products"
import RockPointStore from "../../stores/rockpoints"

import withLayout from "../../components/layout"
import {
  MyPurchaseProxyButton,
  CoinbaseDisclaimer,
} from "../../components/CheckoutHelpers"
import CheckoutPriceCard from "../../components/CheckoutPriceCard"
import CoinbaseWaitingModal from "../../components/CoinbaseWaitingModal"

import coinbaseLogo from "../../imgs/coinbase.png"
// import poweredByStripe from "../../imgs/powered-by-stripe.svg"
// import poweredByStripeCards from "../../imgs/powered_by_stripe_cards.png"
// import cardsMini from "../../imgs/cards-mini.svg"
import loadingSpinner from "../../imgs/loading-spinner.svg"

// import DiscountCoupon from "./components/_DiscountCoupon"
import api from "../../utils/api"
import debounce from "../../utils/debounce"
import transferWise from "../../imgs/wise-logo.png"
import mpFullLogo from "../../imgs/mp-full-logo.png"
import coinifyLogo from "../../imgs/coinify-logo.png"
import cryptomusLogo from "../../imgs/cryptomus-logo.png"
import TrackPurchase from "./components/_TrackPurchase"
// import { Checkbox } from "../../components/CryptoActValidationModal"

const DesktopContainer = styled.div`
  @media screen and (min-width: 961px) {
    // height: 100%;
    width: 100%;
    left: 0;
    position: absolute;

    &:after {
      content: "";
      background: #eefaf4;
      position: absolute;
      left: calc((100vw - 1000px) / 2 + 372px);
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  .child1 {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    @media screen and (min-width: 961px) {
      flex-flow: row;
      padding: 0 20px 20px 20px;
      align-items: normal;
    }
  }

  .c1-flex {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    @media screen and (min-width: 961px) {
      width: 40%;
      padding-right: 4%;
    }
  }

  .c2-flex {
    flex: 1 1 0%;
    background: #eefaf4;
    padding-bottom: 20px;
    @media screen and (min-width: 961px) {
      padding-left: 4%;
      padding-bottom: 0px;
    }
  }

  h3 {
    // color: #9ba1aa;
  }
  h5 {
    font-size: 14px;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    margin-bottom: 24px;

    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }

  .button-container button {
    background: white;
    border: 1px solid #c9c9ca;
    width: 90%;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 4px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    cursor: pointer;
    transition: all 0.35s;
    line-height: 1.5;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.04),
      0px 5px 10px rgba(0, 0, 0, 0.04);

    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04);
    }

    @media screen and (max-width: 960px) {
      width: 100%;
      height: 100px;
    }

    &:disabled {
      transform: scale(0.95);
      background: #f0f0f0;
      cursor: progress;
    }
  }

  .button-container.no-bottom-margin {
    margin-bottom: 0px;
  }

  .blue-color {
    color: #419bf9;
  }

  .text-bold {
    font-weight: 700;
  }
  .add-credit-container {
    background: white;
    border: 1px solid #c9c9ca;
    width: 90%;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 4px 0;

    cursor: pointer;
    transition: all 0.35s;
    line-height: 1.5;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.04),
      0px 5px 10px rgba(0, 0, 0, 0.04);

    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04);
    }
    display: block;
    text-align: center;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
  .add-credit-container.active {
    border: 2px solid #667af4;
    outline: none;
  }
  .add-credit-container-texts {
    margin: 0 20px !important;
    overflow-wrap: break-word;
  }

  .button-container button.active {
    border: 2px solid #667af4;
    outline: none;
  }

  img {
    max-width: 100%;

    @media screen and (max-width: 960px) {
      max-height: 100%;
    }
  }

  .stripe {
    margin-top: 4px;
    margin-bottom: 8px;

    @media screen and (min-width: 961px) {
      max-width: 60%;
    }
  }

  .gray {
    color: #9ba1aa;
  }

  .small {
    font-size: 12px;
    line-height: 1.2;
  }

  .purchase-button-wrapper {
    width: 90%;
    margin: 0 auto;
    @media screen and (min-width: 961px) {
      width: 100%;
    }
  }

  .payment-methods-wrapper-outer {
    .payment-methods-wrapper {
      h5 {
        margin-bottom: 10px;
      }
      .disclaimer {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    @media screen and (min-width: 961px) {
      margin-top: 40px;
    }
  }
  .logo-wrapper {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const CHECKOUT_STATUS = {
  INITIAL: "INITIAL",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
}

const getTitle = status => {
  switch (status) {
    case CHECKOUT_STATUS.SUCCESS:
      return "Success!"

    default:
      return "Checkout"
  }
}

const getButtonTitle = (status, method, total, purchasing) => {
  switch (status) {
    case CHECKOUT_STATUS.SUCCESS:
      return "See my proxy!"

    default:
      if (purchasing) {
        return "Processing"
      }
      if (!method && total !== 0) {
        return "Select a payment method"
      }
      return "Buy now"
  }
}

export const METHODS = {
  STRIPE: "STRIPE",
  COINBASE: "COINBASE",
  COINIFY: "COINIFY",
  CRYPTOMUS: "CRYPTOMUS",
}

function AddProxyPage2({
  status = CHECKOUT_STATUS.INITIAL,
  location,
  isMobile,
  authStore,
  navigate,
}) {
  const [method, setMethod] = useState(null)
  const [promo] = useState(null)
  const [purchasing, setPurchasing] = useState(false)
  // const [recurringPayment, setRecurringPayment] = useState(false)
  const recurringPayment = false

  const searchParams = parse(location.search)
  const {
    selectedTier2Id,
    selectedTier3Id,
    locationID,
    selectedBillingCycle,
    multilocationId,
  } = searchParams
  const mountedRef = useRef(true)
  const [invalidationCalled, setInvalidationCalled] = useState(false)
  const invalidatePendingSubscription = debounce(async () => {
    try {
      await api.get(
        `/subscriptions/invalidate?timestamp=${new Date().getTime()}`
      )

      if (mountedRef.current) {
        setInvalidationCalled(true)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Couldnot invalidate subscriptions")
      setInvalidationCalled(true)
    }
  })

  const updateCheckoutPageVisitTime = debounce(async () => {
    try {
      await api.put(`/account/update_checkout_page_visit_timestamp`)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Couldnot update timestamp")
    }
  })

  useEffect(() => {
    invalidatePendingSubscription()
    updateCheckoutPageVisitTime()
    return () => {
      invalidatePendingSubscription.cancel()
      updateCheckoutPageVisitTime.cancel()
      mountedRef.current = false
    }
  }, [])

  if (!authStore.isAuthenticated) return null
  if (!invalidationCalled) {
    return (
      <div style={{ textAlign: "center" }}>
        <img src={loadingSpinner} alt="spinner" />
      </div>
    )
  }
  return (
    <Subscribe to={[RPContainer, ProductStore, RockPointStore]}>
      {(rpstore, productStore, pointsStore) => {
        const handleSuccess = () => {
          rpstore.fetchSubscriptions()
          localStorage.setItem("paymentRedirect", window.location.search)
          localStorage.setItem("paymentType", "subscription")
          navigate(`/payment/success?billing=${selectedBillingCycle}`)
        }

        if (!productStore.state.locationID) {
          productStore.setProductTiers({
            locationID,
            selectedTier2Id,
            selectedTier3Id,
            multilocationId,
          })
        }
        // const isMPManaged = pointsStore.preCreditDigit === 1 //TODO: make clean one later

        const selectedType = productStore.proxyTypes.find(
          elem => elem.id === selectedTier2Id
        )
        if (!selectedType) return null

        const multiLocationProductDetail = multilocationId
          ? productStore.state.selectedMultilocation
          : null

        if (multilocationId && !multiLocationProductDetail) {
          return null
        }

        if (productStore.ipRotations.length === 0) return null

        const selectedRota = productStore.ipRotations.find(
          elem => elem.id === selectedTier3Id
        )

        let typePrice = 0
        let ipPrice = 0
        let subTotal = 0

        if (!multilocationId) {
          if (selectedBillingCycle && selectedBillingCycle === "weekly") {
            if (!selectedType.weeklyPrice) return null
            typePrice = selectedType.weeklyPrice ? selectedType.weeklyPrice : 0
            ipPrice = selectedRota.weeklyPrice ? selectedRota.weeklyPrice : 0
          } else {
            typePrice = selectedType.price ? selectedType.price : 0
            ipPrice = selectedRota.price ? selectedRota.price : 0
          }
          subTotal = typePrice + ipPrice
        } else {
          subTotal =
            selectedBillingCycle && selectedBillingCycle === "weekly"
              ? multiLocationProductDetail.weeklyPrice
              : multiLocationProductDetail.price
        }

        let discount = 0
        let credit = pointsStore.nextBillDiscount

        if (promo) {
          const { amountOff, percentOff } = promo.coupon
          discount = amountOff
            ? subTotal - amountOff
            : (percentOff * subTotal) / 100
          subTotal -= discount
        }

        let total = subTotal
        if (credit > 0) {
          if (subTotal > credit) {
            total = subTotal - credit
          } else {
            credit = subTotal
            total = 0
          }
        }

        const handleClick = e => {
          if (e) {
            e.preventDefault()
          }

          if (status === CHECKOUT_STATUS.SUCCESS) {
            pointsStore.fetchData()
            return navigate("/proxies", { replace: true })
          }
        }

        return (
          <React.Fragment>
            <TrackPurchase status={status} total={total} billing={selectedBillingCycle} />
            <DesktopContainer>
              <div className="child1">
                <div className="c1-flex">
                  <h1>{getTitle(status)}</h1>

                  <div style={{ width: "100%" }}>
                    <h3>Select a payment method</h3>
                    <div className="payment-methods-wrapper-outer">
                      <div
                        style={{ width: "100%" }}
                        className="payment-methods-wrapper"
                      >
                        <h5>Bank / Fiat Currency - Transfer</h5>
                        <div className="button-container">
                          <button
                            disabled={purchasing}
                            onClick={() => {
                              setMethod(null)
                              window.alert(
                                "Please chat with customer service now to get your Wise Checkout URL."
                              )
                            }}
                          >
                            <img src={transferWise} alt="wise" width={"90%"} />
                          </button>
                        </div>
                      </div>
                      {/* <div
                        style={{ width: "100%" }}
                        className="payment-methods-wrapper"
                      >
                        <h5>Credit or debit card</h5> */}
                      {/* Below:=> Stripe checkout template with mp/non_mp user difference */}

                      {/* {!isMPManaged && (
                          <div className="button-container">
                            <button
                              className={
                                method === METHODS.STRIPE ? "active" : ""
                              }
                              onClick={() => setMethod(METHODS.STRIPE)}
                              disabled={purchasing}
                            >
                              <img
                                src={poweredByStripe}
                                alt="Stripe"
                                className="stripe"
                              />
                              <img src={cardsMini} alt="Credit Card" />
                            </button>
                          </div>
                        )}
                        {isMPManaged && (
                          <button
                            className={`add-credit-container`}
                            onClick={() => setMethod(null)}
                          >
                            <img
                              src={poweredByStripeCards}
                              className="poweredByStripeCards"
                              alt="stripe"
                            />
                            <div className="add-credit-container-texts">
                              <p class="blue-color text-bold">
                                Credit and Debit card payments are currently
                                under maintenance, and will be back soon. Thank
                                you for your patience.
                              </p>
                            </div>
                          </button>
                        )} */}

                      {/* Below:=> Stripe checkout under maintainence template */}
                      {/* <button
                          className={`add-credit-container ${
                            method === METHODS.STRIPE ? "active" : ""
                          }`}
                          onClick={() => setMethod(null)}
                        >
                          <img
                            src={poweredByStripeCards}
                            className="poweredByStripeCards"
                            alt="stripe"
                          />
                          <div className="add-credit-container-texts">
                            <p class="blue-color text-bold">
                              Credit and Debit card payments are currently under
                              maintenance, and will be back soon. Thank you for
                              your patience.
                            </p>
                          </div>
                        </button> */}
                      {/* </div> */}
                      <div
                        style={{ width: "100%" }}
                        className="payment-methods-wrapper"
                      >
                        <h5>Cryptocurrency</h5>
                        <p className="disclaimer">
                          Please notice that cryptocurrency payments are non
                          refundable. If this is your first time using our
                          proxies, we recommend to start with a weekly
                          subscription or use Wise to send your payment.
                        </p>
                        <div className="button-container">
                          <button
                            className={
                              method === METHODS.COINBASE ? "active" : ""
                            }
                            disabled={purchasing}
                            onClick={() => setMethod(METHODS.COINBASE)}
                          >
                            <img src={coinbaseLogo} alt="coinbase" />
                          </button>
                        </div>
                        <div className="button-container">
                          <button
                            className={
                              method === METHODS.COINIFY ? "active" : ""
                            }
                            disabled={purchasing}
                            onClick={() => setMethod(METHODS.COINIFY)}
                            style={{ minHeight: "110px" }}
                          >
                            <img
                              src={coinifyLogo}
                              alt="coinify"
                              width={"90%"}
                            />
                            <h4>«Available only for payments over $15»</h4>
                          </button>
                        </div>
                        <div className="button-container no-bottom-margin">
                          <button
                            className={
                              method === METHODS.CRYPTOMUS ? "active" : ""
                            }
                            disabled={purchasing}
                            onClick={() => setMethod(METHODS.CRYPTOMUS)}
                            style={{ minHeight: "110px" }}
                          >
                            <img
                              src={cryptomusLogo}
                              alt="cryptomus"
                              width={"90%"}
                            />
                          </button>
                        </div>

                        {/* {method === METHODS.CRYPTOMUS && (
                          <div className="validate-checkbox">
                            <Checkbox
                              labelText="Enable recurring payments for subscription"
                              checked={recurringPayment}
                              onChange={() =>
                                setRecurringPayment(!recurringPayment)
                              }
                              id="recurringPayment"
                            />
                          </div>
                        )} */}
                      </div>
                      <div style={{ marginBottom: "16px" }} />
                      <div
                        style={{ width: "100%" }}
                        className="payment-methods-wrapper"
                      >
                        <h5>MountProxies Credit</h5>
                        <div
                          className="button-container"
                          style={{ marginBottom: 0 }}
                        >
                          <button
                            disabled={purchasing}
                            style={{ minHeight: "110px" }}
                            onClick={() => {
                              setMethod(null)
                            }}
                            className={
                              method === null &&
                              (status !== CHECKOUT_STATUS.INITIAL ||
                                total === 0)
                                ? "active"
                                : ""
                            }
                          >
                            <img src={mpFullLogo} alt="mp-credit" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CoinbaseDisclaimer />
                  {/* {method === METHODS.COINBASE && <CoinbaseDisclaimer />} */}
                </div>
                <div className="c2-flex">
                  <CheckoutPriceCard
                    {...{
                      subTotal,
                      total,
                      discount,
                      credit,
                      selectedRota,
                      selectedType,
                      selectedBillingCycle,
                      promo,
                      isMobile,
                      pointsStore,
                      multiLocationProductDetail,
                    }}
                  />
                  <div className="purchase-button-wrapper">
                    <MyPurchaseProxyButton
                      selectedFeatures={[selectedTier2Id, selectedTier3Id]}
                      selectedCardId={rpstore.state.selectedCardId}
                      disabled={
                        status !== CHECKOUT_STATUS.INITIAL ||
                        (method === null && total !== 0)
                      }
                      blueButton={
                        method != null ||
                        status !== CHECKOUT_STATUS.INITIAL ||
                        total === 0
                      }
                      onSuccess={handleSuccess}
                      onClick={handleClick}
                      id={
                        status === CHECKOUT_STATUS.INITIAL ? "buynow_btn" : ""
                      }
                      stripeEnabled={method === METHODS.STRIPE || total === 0}
                      subTotal={total}
                      promo={promo}
                      purchasing={purchasing}
                      setPurchasing={setPurchasing}
                      locationID={locationID}
                      multilocationId={multilocationId}
                      pointsStore={pointsStore}
                      selectedBillingCycle={selectedBillingCycle}
                      paymentMethod={method}
                      recurringPayment={recurringPayment}
                    >
                      {getButtonTitle(status, method, total, purchasing)}
                    </MyPurchaseProxyButton>
                  </div>
                </div>
              </div>
            </DesktopContainer>
            <CoinbaseWaitingModal
              isOpen={
                purchasing &&
                (method === METHODS.COINBASE ||
                  method === METHODS.COINIFY ||
                  method === METHODS.CRYPTOMUS)
              }
              paymentMethod={method}
            />
          </React.Fragment>
        )
      }}
    </Subscribe>
  )
}

export default withLayout(AddProxyPage2)
