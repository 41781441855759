import { useRef, useEffect } from "react"

const TrackPurchase = ({ status, total, billing }) => {
  const prevTracked = useRef(false) // Prevent double tracking

  useEffect(() => {
    if (!prevTracked.current && status === "SUCCESS" && total) {
      prevTracked.current = true // Mark as tracked
    //   window.gtag && window.gtag("event", "purchase", {
    //     value: total,
    //     currency: "USD",
    //     billing: billing,
    //   })


      if (typeof window !== "undefined") {
        console.log('Data Layer')
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "purchase",
          value: total,
          currency: "USD",
          billing: billing,
        })

        console.log("Purchase tracked ✅", {
          total,
          billing: billing,
          status,
        })
      }

      
    }
  }, [status, total]) // Runs when status or total updates

  return null // No UI needed
}

export default TrackPurchase;
